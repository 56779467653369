
.intro{
    width: 100%;
    text-align: center;
}
.main {
    color: #111c46;
    width: 100%;
    font-size: 40px;
    font-weight: 200;
    line-height: 70px;
    margin-top: -25px;
    background-color: rgb(15, 21, 45,0.7);
    font-family: "Cairo","Poppins", sans-serif;
    color:rgba(252, 185, 0,0.9);
    
    
    bottom: 0;
    right: 0;
    left: 0;
    
    


}
@media (max-width: 768px){
    .main {
        width:100%;
        text-align: center;
        font-size: 20px;
        margin-top: -5px;
        line-height: 2;
    }
}
.Layout{
    display: grid;
    grid-template-areas: "leftside main rightside";
    grid-template-columns: minmax(0, 3fr) minmax(0, 25fr) minmax(0,3fr);
    column-gap: 25px;
    row-gap: 25px;
    grid-template-rows: auto;
    margin: 25px 0;
}
@media(max-width:768px) {
    .Layout{
        display: flex;
        margin: 5px 20px;
        flex-direction: column;
        
    }
}


