
.intro{
    width: 100%;
    text-align: center;
}
.main {
    color: #111c46;
    width: 100%;
    font-size: 40px;
    font-weight: 200;
    line-height: 70px;


}
@media (max-width: 768px){
    .main {
        width:100%;
        text-align: center;
        font-size: 20px;
        
        line-height: 2;
    }
}
.Layout{
    display: grid;
    grid-template-areas: "leftside main rightside";
    grid-template-columns: minmax(0, 3fr) minmax(0, 25fr) minmax(0,3fr);
    column-gap: 25px;
    row-gap: 25px;
    grid-template-rows: auto;
    margin: 25px 0;
}
@media(max-width:768px) {
    .Layout{
        display: flex;
        margin: 5px 20px;
        flex-direction: column;
        
    }
}

.Container {
    grid-area: main;
    font-size: 36px;
    margin: 25px 0 0;
}

.LoginBox{
    display: flex;
    flex-direction: column;
    
}
.LoginBox button{
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: white;
    border: none;
    font-size: 16px;
    font-weight: 700;
    color: rgba(0,0,0,1);
}
.UserName{
    display: flex;
    flex-direction: column;
    margin: 12px 0;
}
.UserName input{
    border-radius: 20px;
    background-color: rgb(250, 248, 248);
    padding: 2px 12px;
}
.Password{
    display: flex;
    flex-direction: column;
    margin: 12px 0 0;
}
.Password input{
    border-radius: 20px;
    background-color: rgb(250, 248, 248);
    padding: 2px 12px;
}
.ForgotPass{
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 10px;
    
}
.ForgotPass button{
    border-radius: 20px;
    padding: 2px 10px;
    background-color: rgba(250, 248, 248);
}

.SignWithGoogle{
    display: flex;
    justify-content: center;
    margin: 25px 0 10px;
}
.SignWithGoogle button{
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 2px 10px;
    gap: 15px;
    border-radius: 25px;
}
.SignWithGoogle button img{
    width: 48px;
    height: 48px;
    display: flex;
    border-radius: 50%;
    
    
}
.SignWithGoogle button a{
    display: flex;
    flex: 1;
    flex-grow: 1;
    font-size: 16px;
}