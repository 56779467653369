.AccountPage{
    margin-top: 25px;
    grid-area: main;
}
.Accounts{
    display: flex;
    flex-direction: column;
}
.Profile{
    padding: 0;
    margin: 0 0 8px;
    overflow: visible;
    line-height: 1;
    display: flex;
    flex-direction: column;
    opacity: 70%;
}
.Profile:hover{
    opacity: 100%;
}
.Background{
    
    object-fit: contain;
    width: 100%;
    height: 100%;
    z-index: -1;
}
.Profile button{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    background-color: white;
    border: none;
    color:rgba(252, 185, 0);

}
.Profile button t{
    display: flex;
    flex: 1;
    
}

.Profile button img{
    width: 100px;
    height: 100px;
   
    border-radius: 50%;
    padding: 10px 0;

    
}
.Profile a{

    font-size: 24px;
    
}

.Description{
    display: flex;
    flex-direction: column;
    font-size: 16px;
    
}

.Description p{
    font-size: 16px;
    line-height: 1;
    text-align: left;
    margin: 0 10px 10px 20px;
}
.Rowone{
    display: flex;
    justify-content: space-around;
    margin: 10px 0px;
    text-align: left;
}
.Rowone a:hover{
    color:rgba(252, 185, 0);
}

.Rowone a{
    display: flex;
    flex-direction: column;
    font-size: 20px;
    color:rgba(252, 185, 0);
    
}
.Rowone p{
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    text-align: center;
    color:rgba(252, 185, 0);
}
.Rowone p:hover{
    color:rgba(252, 185, 0);
}
.Rowone input{
    border-radius: 24px;
    border-color: rgba(0,0,0,0.5);
}
