.main {
    color:rgba(252, 185, 0);
    width: 100%;
    font-size: 24px;
    font-weight: 200;
    line-height: 20px;
    background-color: rgb(15, 21, 45,0.7);
    font-family: "Cairo","Poppins", sans-serif;
    margin-top: -10px;
    bottom: 0;
    right: 0;
    left: 0;


}
@media (max-width: 768px){
    .main {
        width:100%;
        text-align: center;
        font-size: 20px;
        
        line-height: 2px;
    }
}
.Layout{
    display: flex;
    justify-content: center;
    flex-direction: column;
    text-align: center;
    font-size: 36px;
    font-weight: 600;
    margin: 10px 100px;
}
@media(max-width:768px) {
    .Layout{
        display: flex;
        font-size: 36px;
        margin: 10px 20px;
        flex-direction: column;
        
    }
}

.CommonCard{
    text-align: center;
    overflow: hidden;
    margin-bottom: 12px;
    background-color: rgb(15, 21, 45);
    border-radius: 5px;
    position: relative;
    border: none;
    box-shadow: 0 0 0 2px rgb(0 0 0 / 15%), 0 0 0 rgb( 0 0 0/ 20%);
    margin-top: 10px;
    margin-bottom: 30px;
    cursor: pointer;
}
.Article{
    padding: 0;
    margin: 0 0 8px;
    overflow: visible;
    line-height: 1;
}
@media (max-width: 768px){
    .Article{
        line-height: 1;
    }
}
.SharedActor{
    
    display: flex;
    flex-wrap: nowrap;
    padding: 12px 16px 0;
    margin-bottom: 8px;
    align-items: center;

}
.SharedActor a{
    margin-right: 12px;
    flex-grow: 1;
    overflow: hidden;
    display: flex;
    text-decoration: none;
}
.SharedActor a img{
    width: 48px;
    height: 48px;
}
.SharedActor a div{
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    flex-basis: 0;
    margin-left: 8px;
    overflow: hidden;
}
.SharedActor a div span{
    text-align: left;
}
.SharedActor a div span:first-child{
    font-size: 16px;
    font-weight: 700;
    color:rgba(252, 185, 0);

}
.SharedActor a div span:nth-child(n+1){
    font-size: 14px;
    font-weight: normal;
    color:rgba(252, 185, 0);
}

.SharedActor button{
    position: absolute;
    right: 12px;
    top: 0;
    background: transparent;
    border: none;
    outline: none;
}
.Description{
    padding: 0 16px 10px;
    overflow: hidden;
    color:rgba(252, 185, 0);
    text-align: center;
    font-size: 16px;
    font-weight: normal;
    
}
.SharedImg{
    margin-top: 20px;
    width: 100%;
    display: block;
    position: relative;
    background-color: #f9fafb;
    border: solid rgba(0,0,0,0.3);
    border-radius: 5px;
}
.SharedImg img{
    object-fit: contain;
    width: 100%;
    height: 100%;
}
.Loading{
    text-align: center;
}
.LoadBar{
    width: 30px;
}
