
.intro{
    width: 100%;
    text-align: center;
}
.Main {
    color:rgba(252, 185, 0);

    width: 100%;
    font-size: 40px;
    font-weight: 200;
    line-height: 70px;
    background-color: rgb(15, 21, 45,0.7);
    position: fixed;
    top: 5rem;
    bottom: 0;
    right: 0;
    left: 0;


}
@media (max-width: 768px){
    .Main {
        width:100%;
        text-align: center;
        font-size: 20px;
        
        line-height: 2;
    }
}
.Layout{
    display: grid;
    grid-template-areas: "leftside main rightside";
    grid-template-columns: minmax(0, 3fr) minmax(0, 25fr) minmax(0,3fr);
    column-gap: 25px;
    row-gap: 25px;
    grid-template-rows: auto;
    margin: 25px 0;
}
@media(max-width:768px) {
    .Layout{
        display: flex;
        margin: 5px 20px;
        flex-direction: column;
        
    }
}

.Container {
    grid-area: main;
    font-size: 36px;
    margin: 25px 0 0;
    
}

.LoginBox{
    display: flex;
    flex-direction: column;
    
}
.LoginBox button{
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: white;
    border: none;
    font-size: 16px;
    font-weight: 700;
    color: rgba(0,0,0,1);
}
.Email{
    display: flex;
    flex-direction: column;
    margin: 7px 0;
}
.Email input{
    border-radius: 20px;
    background-color: rgb(250, 248, 248);
    padding: 2px 10px;
}
.PhoneNumber{
    display: flex;
    flex-direction: column;
    margin: 7px 0 0;
}
.PhoneNumber input{
    border-radius: 20px;
    background-color: rgb(250, 248, 248);
    padding: 2px 12px;
}
.Subject{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    margin-top: 10px;
    
}
.Subject button{
    border-radius: 20px;
    padding: 2px 10px;
    background-color: rgb(255, 255, 255);
    border: none;
    font-size: 16px;
    font-weight: 700;
    color: rgba(0,0,0,1);
}

.SubjectLine{
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: rgb(255, 255, 255);

    
}
.SubjectLine input{
    border-radius: 24px;
    background-color: rgb(250, 248, 248);
    padding: 2px 10px;
}

.Message{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    margin-top: 10px;
    
    
}
.Message button{
    border-radius: 20px;
    padding: 2px 10px;
    background-color: rgb(255, 255, 255);
    border: none;
    font-size: 16px;
    font-weight: 700;
    color: rgba(0,0,0,1);
    display: flex;
    flex-grow: 1;
    
}

.MessageText{
    display: flex;
   
    flex-grow: 1;
    align-items: center;
    background-color: rgb(255, 255, 255);

    
}
.MessageText p{
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    text-align: center;
    
    font-size: 20px;
    font-weight: 700;
    color: rgba(0,0,0,1);
}
.MessageText input{
    border-radius: 24px;
    margin: 0px 5px;
}