.ShareBox{
    display: flex;
    flex-direction: column;
    color: #958b7b;
    margin: 10px 0 8px;
    background: white;
}

.Logos{
    display: flex;
    justify-content: space-around;
    height: 75px;
}
.Logos button img{
    
    align-items: center;
    max-height:40px;
    max-width: 70px;
}

.Logos button span{
    justify-content: center ;
    align-items: center;
}
.Logos button {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    gap:5px;
    outline: none;
    color: rgba(0,0,0,0.6);
    background-color: #fff;
    font-size: 14px;
    line-height: 1.5;
    min-height: 45px;
    border-color: white;
    border-width: 0px;
    font-weight: 600;
}

.User{
    display: flex;
    
}
.User img{
    width: 48px;
    border-radius: 50%;
    margin-right: 8px;
    margin-left: 8px;
}
.User button{
    margin: 4px 0;
    margin-right: 8px;
    flex-grow: 1;
    border-radius: 35px;
    padding-left: 16px;
    border: 1px solid rgba(0,0,0,0.15);
    color: rgba(0,0,0,0.5);
    font-size: 14px;
    line-height: 1.5;
    font-weight: 600;
    text-align: left;
    background-color: white;
}
