.main {
    color:rgba(252, 185, 0,0.9);
    width: 100%;
    font-size: 24px;
    font-weight: 200;
    line-height: 20px;
    background-color: rgb(15, 21, 45,0.7);
    position: fixed;
    top: 5rem;
    bottom: 0;
    right: 0;
    left: 0;
   
    


}
@media (max-width: 768px){
    .main {
        width:100%;
        text-align: center;
        font-size: 20px;
        padding-bottom: 200px;
        line-height: 2px;
        
    }
}
.Layout{
    display: flex;
    justify-content: center;
    flex-direction: column;
    text-align: center;
    font-size: 24px;
    font-weight: 600;
    margin: 10px 80px;
}
@media(max-width:768px) {
    .Layout{
        display: flex;
        font-size: 24px;
        margin: 10px 20px;
        flex-direction: column;
        
    }
}

.CommonCard{
    text-align: center;
    overflow: hidden;
    margin-bottom: 12px;
    background-color: rgb(15, 21, 45);
    border-radius: 5px;
    position: relative;
    border: none;
    box-shadow: 0 0 0 2px rgb(0 0 0 / 15%), 0 0 0 rgb( 0 0 0/ 20%);
    margin-top: 10px;
    margin-bottom: 30px;
    cursor: pointer;
    
}
.Skills{
    display: flex;
    justify-content: space-around;
    font-size: 17px;
    margin: 5px 0;
    padding: 0 10px;
}
@media (max-width: 768px){
    .Skills{
        margin: 10px 0;
        font-size: 12px;
        padding: 0 45px 0 45px;
    }
}
.Skills li{
    width: 170px;
    margin: 5px;
    text-align: left;
}
@media (max-width: 768px){
    .Skills li{
        width: 110px;
        font-size: 12px;
        line-height: 12px;
        
    }
}
.Skills li li{
    padding-left: 15px;
    margin-top: 20px;
    text-align: left;
}

@media (max-width: 768px){
    .Skills li li{
        width: 110px;
        font-size: 12px;
        line-height: 11px;
        padding-left: 15px;
        margin-top: 10px;
        
        
    }
}

