.CommonCard{
    text-align: center;
    overflow: hidden;
    margin-bottom: 12px;
    background-color: rgb(15, 21, 45,0.9);
    border-radius: 5px;
    position: relative;
    border: none;
    box-shadow: 0 0 0 2px rgb(0 0 0 / 15%), 0 0 0 rgb( 0 0 0/ 20%);
}
.Article{
    padding: 0;
    margin: 0 0 8px;
    overflow: visible;
    line-height: 1;
}
@media (max-width: 768px){
    .Article{
        line-height: 1;
    }
}
.SharedActor{
    
    display: flex;
    flex-wrap: nowrap;
    padding: 12px 16px 0;
    margin-bottom: 8px;
    align-items: center;

}
.SharedActor a{
    margin-right: 12px;
    flex-grow: 1;
    overflow: hidden;
    display: flex;
    text-decoration: none;
}
.SharedActor a img{
    width: 48px;
    height: 48px;
}
.SharedActor a div{
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    flex-basis: 0;
    margin-left: 8px;
    overflow: hidden;
}
.SharedActor a div span{
    text-align: left;
}
.SharedActor a div span:first-child{
    font-size: 16px;
    font-weight: 700;
    color:rgba(252, 185, 0,0.9);
}
.SharedActor a div span:nth-child(n+1){
    font-size: 14px;
    color:rgba(252, 185, 0,0.9);}

.SharedActor button{
    position: absolute;
    right: 12px;
    top: 0;
    background: transparent;
    border: none;
    outline: none;
}
.Description{
    padding: 0 16px;
    overflow: hidden;
    color:rgba(252, 185, 0,0.9);    text-align: center;
    font-size:24px;
    font-weight: 700;
}
@media (max-width:768px) {
    .Description{
        font-size: 18px;
    }
}
.SharedImg{
    margin-top: 8px;
    width: 100%;
    display: block;
    position: relative;
    background-color: #f9fafb;
}
.SharedImg img{
    object-fit: contain;
    width: 100%;
    height: 100%;
}
.Loading{
    text-align: center;
}
.LoadBar{
    width: 30px;
}