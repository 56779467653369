.Container {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 9999;
    font-size: 25px;
    background-color: rgba(0,0,0,0.4);
   
}

.Content{
    width: 100%;
    max-width: 552px;
    background-color: white;
    max-height: 90%;
    overflow: initial;
    border-radius: 5px;
    position: relative;
    display: flex;
    flex-direction: column;
    top: 32px;
    margin: 0 auto;

}

.Headers{
    display: flex;
    padding: 16px 20px;
    border-bottom: 1 px solid rgba(0,0,0,0.15);
    font-size: 12px;
    line-height: 1.5;
    color: rgba(0,0,0,0.6);
    font-weight: 400;
    justify-content: space-between;
    align-items: center;
}
.Headers button{
    height: 40px;
    width: 40px;
    min-width: auto;
    color: rgba(0,0,0,0.15);
    border-radius: 5px;
}
.Headers button img{
    pointer-events: none;
    height: 20px;
    width: 20px;
    
    
}
.SharedContent{
    display: flex;
    flex-direction: column;
    flex-wrap: 1;
    overflow-y: auto;
    vertical-align: baseline;
    background: transparent;
    padding: 8px 12px;
}

.UserInfo{
    display: flex;
    align-items: center;
    padding: 12px 24px;
}
.UserInfo img{
    height: 48px;
    width: 48px;
    background-clip: content-box;
    border: 2px solid transparent;
    border-radius: 50%;
}

.UserInfo span{
    font-weight: 600;
    font-size: 18px;
    line-height: 1.5;
    margin-left: 10px;
}

.Editor{
    display: flex;
    flex-direction: column;
    padding: 12px 24px;
    min-height: 100px;
    resize: none;
}
.Editor textarea{
    display: flex;
    flex-grow: 1;
    width: 100%;
    height: 150px;
    font-size: 16px;
    min-height: 100px;
    resize: none;
}

.Editor input{
}    

.SharedCreation{
    display: flex;
    justify-content: space-between;
    padding: 12px 24px 12px 16px;

}
.AttachAssets{
    align-items: center;
    display: flex;
    padding-right: 8px;
}
.AssetButton{
    display: flex;
    align-items: center;
    justify-content: center;
    height: 40px;
    width: 40px;
    color: rgba(0,0,0,0.6);
    border:1px solid rgba(0,0,0,0.3);
}
.AssetButton img{
    height: 20px;
    width: 20px;
}
.ShareComment{
    padding-left:8px ;
    margin-right: auto;
    border-left: 1px solid rgba(0,0,0,0.1);
}
.ShareComment .AssetButton button{
    padding: 12px 12px;
    margin-left: 10px;
    border-radius: 5px;
    border: 1px solid rgba(0,0,0,0.3);
    
    
}
.PostButton{
}

.PostButton .AssetButton button{
    padding: 12px 16px;
    border-radius: 20px;
    color: white;
    background: #0a66c2;
}

.PostButton .AssetButton button:hover{
    background: #004182;
}

.UploadImage{
    display: flex;
    flex-direction: column;
    text-align: center;
    width: 100%;
    
   
}
.UploadImage span{
    font-size: 16px;
    margin-top: -20px;
    margin-bottom: -20px;
    text-align: center;

    
}
