.main {
    color:rgba(252, 185, 0,0.9);
    width: 100%;
    font-size: 24px;
    font-weight: 200;
    line-height: 20px;
    background-color: rgb(15, 21, 45,0.7);
    font-family: "Cairo","Poppins", sans-serif;
    margin-top: -10px;
    
    
    


}
@media (max-width: 768px){
    .main {
        width:100%;
        text-align: center;
        font-size: 20px;
        
        line-height: 2px;
    }
}
.Layout{
    display: flex;
    justify-content: center;
    flex-direction: column;
    text-align: center;
    font-size: 36px;
    font-weight: 600;
    margin: 10px 100px;
}
@media(max-width:768px) {
    .Layout{
        display: flex;
        font-size: 36px;
        margin: 10px 20px;
        flex-direction: column;
        line-height: 1;
        
    }
}

.CommonCard{
    text-align: center;
    overflow: hidden;
    margin-bottom: 12px;
    background-color: rgb(15, 21, 45);
    border-radius: 5px;
    position: relative;
    border: none;
    box-shadow: 0 0 0 2px rgb(0 0 0 / 15%), 0 0 0 rgb( 0 0 0/ 20%);
    margin-top: 10px;
    margin-bottom: 30px;
    cursor: pointer;
}
.Article{
    padding: 0;
    margin: 0 0 8px;
    overflow: visible;
    line-height: 1;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 0 20px;
    
}
@media (max-width: 768px){
    .Article{
        line-height: 2;
        margin: 0 5px;
    }
}

.CompanyName{
    padding: 20px 16px 20px;
    overflow: hidden;
    color:rgba(252, 185, 0,0.9);
    text-align: center;
    font-size: 30px;
    font-weight: 600;
    
}
@media ( max-width: 768px){
    .CompanyName{
        padding: 10px 5px 10px;
        font-size: 20px;
    }
}
.Duration{
    font-size: 18px;
    font-weight: 600;
}
@media ( max-width: 768px){
    .Duration{
        font-size: 15px;
    }
}
.Position{
    font-size: 17px;
    text-align: left;
    padding-left: 36px;
    margin-top: -5px;
}
@media ( max-width: 768px){
    .Position{
        font-size: 15px;
        padding-left: 10px;
        margin-top: -5px;
    }
}
.CompanyDescription{
    color:rgba(252, 185, 0,0.9);
    font-size: 18px;
    font-weight: 100;
    text-align: left;
    margin: 0 36px;
}
@media ( max-width: 768px){
    .CompanyDescription{
        margin: 0 10px;
        font-size: 14px;
    }
}
.Loading{
    text-align: center;
}
.LoadBar{
    width: 30px;
}
.newline{
    white-space: pre-line;
    line-height: 1.4;
}
