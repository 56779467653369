.header {
  height: 5rem;
  width: 100%;
  background-color: #0F152D;
  display: flex;
  align-items: center;
  justify-content: start;
  font-family: "Cairo","Poppins", sans-serif;
  
}
@media (max-width: 768px) {
  .header {
    margin: auto;
    width: 100%;
    min-height: 0px;
    align-items: center; 
    justify-content: space-around;
  
  }

}
 


.logo{
  width: 90px;
  border-radius: 24px;
}
@media (max-width: 768px) {
  .logo {
    width: 40px;
  
  }

}

.navmenue {
  display: flex;
  flex:1;
  align-items: center;
  justify-content: center;
  
}

@media (max-width: 768px) {
  .navmenue {
    
    margin: auto;
    min-height: 0px;
    padding: 0px 0px;
    align-items: center; 
    justify-content: center;
    margin-left: 0px;
  
  }

}


.navmenue a {
  text-decoration: none;
  color: rgba(252, 185, 0,0.6);
  padding: 15px 15px;
  border-radius: 24px;
  margin-left: 12px;
  transition-duration: 250ms;
}

@media (max-width: 768px) {
  .navmenue a{
    font-size: 13px;
    margin-left: 0;
    padding: 15px 5px;
  }
}

.navmenue a:hover {
  text-decoration: none;
  background-color: rgba(228,228,228,0.08);
  color: rgba(252, 185, 0,0.9);
  transform: translate3d(0,-2px,0);

}
.navmenue a:active{
  color: rgba(252, 185, 0,1);

}
.navmenue a:active::after{
  color: rgba(228, 228, 228, 1);
  background-color:rgba(228,228,228,0.08);
}




.User{
  width: 80px;
  height: 60px;
  border-radius: 24px;
  cursor: pointer;
  margin: auto;
  transition-duration: 250ms;
  display: block;
  flex-direction: column;
  color: white;
  
  
}

  
.User:hover p{
  color: rgba(0,0,0,1);
  border-color: rgba(0,0,0,0.5);
  border-radius: 12px;
  text-align: center;
}
@media (max-width: 768px) {
  .User {
    
    width: 80px;
    margin-right: 0;
    justify-content: center;
  
  }

}
.UserImage{
  width: 40px;
  border-radius: 24px;
  display: block;
  justify-content: center;
  opacity: 0.5;
  margin-left: 7px;
}
.User:hover img{
  opacity: 0.9;
}
.User span{
  display: block;
  color: rgb(235, 233, 233,0.5);
  margin-left: 15px;
}
.User:hover span{
  color: rgb(235, 233, 233,1);
}
.User p{
  color: rgba(0,0,0,0);
  border-style: groove;
  border-color: rgba(0,0,0,0);
  
  
}
@media (max-width:768px){
  .User p{
    margin-top: 5px;
    
    
    

  }
}
.UserImagetwo{
  width: 40px;
  border-radius: 24px;
  display: block;
  justify-content: center;
  opacity: 0.5;
  margin-left: 7px;
  margin-top: 10px;
}




